var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropdown-item", class: { disabled: _vm.disabled } },
    [
      _vm._t("default", null, null, {
        disabled: _vm.disabled,
        selected: _vm.selected
      }),
      _vm.selected ? _c("BIconCheck") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }